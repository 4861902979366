var currentUrl = window.location.pathname;
var indexUrl = "/";

if(currentUrl === indexUrl){
    var offsetFix = 73;

    if (screen.width >= 768) {
        offsetFix = 77;
    }

    if (screen.width >= 1200) {
        offsetFix = 126;
    }

    AOS.init()
        
    $(document).ready(function(){
        setTimeout(() => {
            var currentUrl = window.location.href;
            var currentHash = currentUrl.split('#').pop();
            var currentHashFormat = currentHash.substring(8);
            history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);

            switch (currentHashFormat) {
                case 'a-propos':
                    $('.a-propos').trigger( "click" );
                    break;
            
                case 'services':
                    $('.services').trigger( "click" );
                break;

                case 'projects':
                    $('.projects').trigger( "click" );
                break;

                case 'blog':
                    $('.blog').trigger( "click" );
                break;

                case 'contact':
                    $('.contact').trigger( "click" );
                break;
            } 
        }, 50);

        $("#testimonial-slider").owlCarousel({
            autoplay:true,
            responsiveClass:true,
            dots:false,
            loop:true,
            responsive:{
                0:{
                    items:1,
                },
                992:{
                    items:2,
                },
                1400:{
                    items:3,
                }
            }
        });

        $("#values-slider").owlCarousel({
            responsiveClass:true,
            responsive:{
                0:{
                    loop:true,
                    autoplay:true,
                    items:1,
                },
                768:{
                    loop:true,
                    autoplay:true,
                    items:2,
                },
                1200:{
                    loop:true,
                    autoplay:true,
                    items:3,
                },
                1400:{
                    mouseDrag:false,
                    touchDrag:false,
                    pullDrag:false,
                    items:4,
                }
            }
        });

        $("#services-slider").owlCarousel({
            responsiveClass:true,
            responsive:{
                0:{
                    items: 1,
                    autoplay:true,
                },
                576:{
                    items: 1,
                    dots: false,
                    nav: true,
                },                
                868:{
                    items: 2,
                    dots: false,
                    nav: true,
                },
                1200:{
                    loop:true,
                    items:3,
                    dots: false,
                    nav: true,
                },
                1400:{
                    loop:true,
                    items:3,
                    dots: false,
                    nav: true,
                }
            }
        });
        
        if($('#map-activate-js').val() == 1) {
            initMap();
        }
    });

    var contacts = JSON.parse($('#contacts-js').val());
    var choice = $('#map-type-js').val();
    var markerStyle = $('#marker-js').val();
    var carte = null;
    var marker = null;
    var url = null;
    var locations = [];
    var latitudes = [];
    var rootUrl = window.location.origin;

    switch (markerStyle) {
        case "1":
            url = rootUrl + "/images/leaflet/marker-icon-1.webp";
        break;
        case "2":
            url = rootUrl + "/images/leaflet/marker-icon-2.webp";
        break;
        case "3":
            url = rootUrl + "/images/leaflet/marker-icon-3.webp";
        break;
        case "4":
            url = rootUrl + "/images/leaflet/marker-icon-4.webp";
        break;
    }

    function style(feature) {
        return {
            weight: 0.5,
            opacity: 0.5,
            color: '#3388ff',
            dashArray: '3',
            fillColor: '#3388ff',
            fillOpacity: 0.2
        };
    }

    function initMap(){
        carte = L.map('map', {scrollWheelZoom: false, dragging: window.matchMedia('(min-width: 768px)').matches, zoomSnap: 0.5, zoomDelta: 0.5});

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        {attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors', minZoom: 3, maxZoom: 16}).addTo(carte);

        if(choice == "no_zone") {
            $.each(contacts, function(key, contact) {
                const location = contact.location;
                const locationSplit = location.split(', ')
                const lat = parseFloat(locationSplit[0]);
                const lon = parseFloat(locationSplit[1]);
                const myIcon = L.icon({
                    iconUrl: url,
                    iconSize:    [40, 40],
                    iconAnchor:  [20, 40],
                    popupAnchor: [0, -35],
                });

                locations.push([lat, lon]);
                latitudes.push(lat);
                marker = L.marker([lat, lon], {icon: myIcon}).addTo(carte);
                
                var phoneNumber = "";
                var mail = "";
                if(contact.phone_number != null){phoneNumber = '<div class="mt-1"><span class="fw-bold">Tel : </span>' + contact.phone_number + '</div>'};
                if(contact.mail != null){mail = '<div class="mt-1"><span class="fw-bold">E-mail : </span>' + contact.mail + '</div>'};

                marker.bindPopup(
                    '<div class="text-center"><div class="fw-bold">' + contact.name +
                    '</div><div class="mt-1">' + contact.address + ' ' + contact.zip_code + ' ' + contact.city +
                    '</div>'+ phoneNumber + mail + '</div>'
                );
            });

            carte.fitBounds(locations);

            var minLat = Math.min(...latitudes);
            var maxLat = Math.max(...latitudes);
            var distanceM = carte.distance(L.latLng(minLat, 1.5), L.latLng(maxLat, 1.5));
            var distanceKm = distanceM / 1000;

            if(distanceKm == 0 ){
                carte.zoomOut(7);
            }

            if(distanceKm > 0 && distanceKm <= 25 ){
                carte.zoomOut(1);
            }

            if(distanceKm > 25){
                carte.zoomOut(0.5);
            }
        }

        if(choice == 'radius'){
            $.each(contacts, function(key, contact){
                const location = contact.location;
                const locationSplit = location.split(', ')
                const lat = parseFloat(locationSplit[0]);
                const lon = parseFloat(locationSplit[1]);

                const myIcon = L.icon({
                    iconUrl: url,
                    iconSize:    [40, 40],
                    iconAnchor:  [20, 40],
                    popupAnchor: [0, -35],
                });

                if(contact.is_radius == "oui"){
                    var radius = contact.radius * 1000;
                    const circle = L.circle([lat, lon], radius, {
                        weight: 1,
                        color: '#3388ff',
                        opacity: 0.5,
                        fillColor: '#3388ff',
                        fillOpacity: 0.2
                    }).addTo(carte);
                }

                locations.push([lat, lon]);
                latitudes.push(lat);
                marker = L.marker([lat, lon], {icon: myIcon}).addTo(carte);

                var phoneNumber = "";
                var mail = "";
                if(contact.phone_number != null){phoneNumber = '<div class="mt-1"><span class="fw-bold">Tel : </span>' + contact.phone_number + '</div>'};
                if(contact.mail != null){mail = '<div class="mt-1"><span class="fw-bold">E-mail : </span>' + contact.mail + '</div>'};

                marker.bindPopup(
                    '<div class="text-center"><div class="fw-bold">' + contact.name +
                    '</div><div class="mt-1">' + contact.address + ' ' + contact.zip_code + ' ' + contact.city +
                    '</div>'+ phoneNumber + mail + '</div>'
                );
            });

            carte.fitBounds(locations);

            var minLat = Math.min(...latitudes);
            var maxLat = Math.max(...latitudes);
            var distanceM = carte.distance(L.latLng(minLat, 1.5), L.latLng(maxLat, 1.5));
            var distanceKm = distanceM / 1000;

            if(distanceKm == 0 ){
                carte.zoomOut(7);
            }

            if(distanceKm > 0 && distanceKm <= 25 ){
                carte.zoomOut(1);
            }

            if(distanceKm > 25){
                carte.zoomOut(0.5);
            }
        }

        if(choice == 'france'){
            $.each(contacts, function(key, contact){
                const location = contact.location;
                const locationSplit = location.split(', ')
                const lat = parseFloat(locationSplit[0]);
                const lon = parseFloat(locationSplit[1]);

                const myIcon = L.icon({
                    iconUrl: url,
                    iconSize:    [40, 40],
                    iconAnchor:  [20, 40],
                    popupAnchor: [0, -35],
                });

                locations.push([lat, lon]);
                latitudes.push(lat);
                marker = L.marker([lat, lon], {icon: myIcon}).addTo(carte);

                var phoneNumber = "";
                var mail = "";
                if(contact.phone_number != null){phoneNumber = '<div class="mt-1"><span class="fw-bold">Tel : </span>' + contact.phone_number + '</div>'};
                if(contact.mail != null){mail = '<div class="mt-1"><span class="fw-bold">E-mail : </span>' + contact.mail + '</div>'};

                marker.bindPopup(
                    '<div class="text-center"><div class="fw-bold">' + contact.name +
                    '</div><div class="mt-1">' + contact.address + ' ' + contact.zip_code + ' ' + contact.city +
                    '</div>'+ phoneNumber + mail + '</div>'
                );
            });

            L.geoJson(dataFrance, {
                    style: style,
            }).addTo(carte);

            carte.setView([47, 3], 5);
        }

        if(choice == 'europe'){
            $.each(contacts, function(key, contact){
                const location = contact.location;
                const locationSplit = location.split(', ')
                const lat = parseFloat(locationSplit[0]);
                const lon = parseFloat(locationSplit[1]);

                const myIcon = L.icon({
                    iconUrl: url,
                    iconSize:    [40, 40],
                    iconAnchor:  [20, 40],
                    popupAnchor: [0, -35],
                });

                locations.push([lat, lon]);
                latitudes.push(lat);
                marker = L.marker([lat, lon], {icon: myIcon}).addTo(carte);
                
                var phoneNumber = "";
                var mail = "";
                if(contact.phone_number != null){phoneNumber = '<div class="mt-1"><span class="fw-bold">Tel : </span>' + contact.phone_number + '</div>'};
                if(contact.mail != null){mail = '<div class="mt-1"><span class="fw-bold">E-mail : </span>' + contact.mail + '</div>'};

                marker.bindPopup(
                    '<div class="text-center"><div class="fw-bold">' + contact.name +
                    '</div><div class="mt-1">' + contact.address + ' ' + contact.zip_code + ' ' + contact.city +
                    '</div>'+ phoneNumber + mail + '</div>'
                );
            });

            L.geoJson(dataEurope, {
                style: style,
            }).addTo(carte);

            carte.setView([55, 14], 3);
        }
    }
    var aboutOffsetTop = $('#section-a-propos').offset().top - offsetFix;
    var aboutOuterHeight = $('#section-a-propos').outerHeight(true);
    var aboutOffsetBottom = aboutOffsetTop + aboutOuterHeight;
    var servicesOffsetTop = $('#section-services').offset().top - offsetFix;
    var servicesOuterHeight = $('#section-services').outerHeight(true);
    var servicesOffsetBottom = servicesOffsetTop + servicesOuterHeight;
    var projectsOffsetTop = $('#section-projects').offset().top - offsetFix;
    var projectsOuterHeight = $('#section-projects').outerHeight(true);
    var projectsOffsetBottom = projectsOffsetTop + projectsOuterHeight;
    if ($('#section-blog').length > 0){
        var blogOffsetTop = $('#section-blog').offset().top - offsetFix;
        var blogOuterHeight = $('#section-blog').outerHeight(true);
        var blogOffsetBottom = blogOffsetTop + blogOuterHeight; 
    }
    var contactOffsetTop = $('#section-contact').offset().top - offsetFix;
    var contactOuterHeight = $('#section-contact').outerHeight(true);
    var contactOffsetBottom = contactOffsetTop + contactOuterHeight; 

    $(document).on('scroll', function(){
        if ($(document).scrollTop() > aboutOffsetTop - 150 && $(document).scrollTop() < aboutOffsetBottom - 150){
            $('.a-propos').addClass('active');
        } else {
            $('.a-propos').removeClass('active')
        }

        if ($(document).scrollTop() > servicesOffsetTop - 150 && $(document).scrollTop() < servicesOffsetBottom - 150){
            $('.services').addClass('active');
        } else {
            $('.services').removeClass('active')
        }

        if ($(document).scrollTop() > projectsOffsetTop - 150 && $(document).scrollTop() < projectsOffsetBottom - 150){
            $('.projects').addClass('active');
        } else {
            $('.projects').removeClass('active')
        }

        if ($('#section-blog').length > 0){
            if ($(document).scrollTop() > blogOffsetTop - 150 && $(document).scrollTop() < blogOffsetBottom - 150){
                $('.blog').addClass('active');
            } else {
                $('.blog').removeClass('active')
            }
        }

        if ($(document).scrollTop() > contactOffsetTop - 150 && $(document).scrollTop() < contactOffsetBottom - 150){
            $('.contact').addClass('active');
        } else {
            $('.contact').removeClass('active')
        }
    });

    $('.a-propos, .services, .projects, .blog, .contact').on('click', function (event) {
        var url = this.href;
        var hash = url.split('#').pop();
        var offsetClick = $('#'+hash).offset().top - offsetFix;
        var hashFormat = hash.substring(8);
                
        event.preventDefault();
        $('html, body').animate({
            'scrollTop':offsetClick
        }, 300)

        $('.navbar li a').removeClass('active');
        $('.'+hashFormat).addClass('active');

        $('.navbar-toggler').addClass('collapsed');
        $('#navbarSupportedContent').removeClass('show');
    });

    $('.service').on( "click", function() {
        if(screen.width < 1200) {
            $('#service-desc-'+this.id).toggleClass('opacity-100'); 
            $('#service-title-'+this.id).toggleClass('opacity-0');
            $('#service-after-'+this.id).toggleClass('bg-transparent');
        }
    })

    $(".modal-close").on("click", function () {
        $('#confirmModal').removeClass('show d-block');
        $('.modal-backdrop').addClass('d-none');
    });

    $(".modal-error-close").on("click", function () {
        $('#errorModal').removeClass('show d-block');
        $('.modal-backdrop').addClass('d-none');
    });
}

$(window).scroll(function () {
    if ($(this).scrollTop() > 320) {
        $('#top-header').addClass('h-0');
    } else {
        $('#top-header').removeClass('h-0');
    }
    
    if ($(this).scrollTop() > 550) {
        $('#scroll-top').fadeIn();
    } else {
        $('#scroll-top').fadeOut();
        }
    });
    
    $('#scroll-top').click(function () {
    $('body,html').animate({
        scrollTop: 0
    }, 300);
    return false;
});
